<template>
  <div class="container-custom" :style="styleObject">
    <div class="content-custom" v-if="isLoadDone">
      <div class="l-header px-0 py-1">
        <div class="d-flex justify-content-center mt-1">
          <img
            v-if="merchantLogo"
            :src="merchantLogo"
            class="avatar-header avatar-md border-radius-lg"
            alt="user1"
          />
          <img
            v-else
            src="../assets/img/logo.png"
            class="avatar-header avatar-md border-radius-lg"
            alt="user1"
          />
        </div>
      </div>
      <div class="mt-3 px-3">
        <div style="color: var(--color); font-size: 18px">
          Choose number of guests
        </div>
        <div class="number-guest-list mt-3 d-flex">
          <div
            v-for="index in numberOfGuests"
            :key="index"
            :class="[
              'number-guest-box',
              { active: numberOfGuestsSelected == index },
            ]"
            :id="index + '-guest'"
            @click="selectNumberOfGuests(index)"
          >
            {{ index }}
          </div>
        </div>
        <div class="mt-5 text-center">
          <material-button class="btn-submit" @click="gotoCategory()"
            >Proceed</material-button
          >
        </div>
      </div>
    </div>
    <div class="position-fixed top-1 end-1 z-index-2 message-success">
      <material-snackbar
        v-if="snackbar === 'danger' || snackbar === 'success'"
        title=""
        date=""
        :description="message"
        :icon="{ component: 'campaign', color: 'white' }"
        :color="snackbar"
        :close-handler="closeSnackbar"
      />
    </div>
    <material-loading :active="loaderActive" />
  </div>
</template>

<script>
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import MaterialLoading from "@/components/MaterialLoading.vue";
import { localStorageName } from "@/config/utils";
import MaterialButton from "@/components/MaterialButton.vue";
import $ from "jquery";
import OrderService from "../services/order.service";

export default {
  name: "Choose number of guests",
  components: {
    MaterialSnackbar,
    MaterialLoading,
    MaterialButton,
  },
  data() {
    return {
      isLoadDone: true,
      snackbar: null,
      message: "",
      merchantLogo: localStorage.getItem(localStorageName("MERCHANT_LOGO"), ""),
      numberOfGuests: 10,
      numberOfGuestsSelected: 0,
      prefixUrl: "",
      tableCode: "",
    };
  },
  async created() {
    this.prefixUrl = await this.$store.dispatch(
      "prefixUrl",
      this.$route.params
    );
    this.tableCode = this.$route.params.table_code;

    if (this.tableCode) {
      this.getNumberOfGuest();
    } else {
      this.$router.push(this.prefixUrl + "/order");
    }
  },
  computed: {
    styleObject() {
      return {
        "--color": this.$store.state.color,
        "--label-color": this.$store.state.labelColor,
      };
    },
  },
  methods: {
    selectNumberOfGuests(index) {
      $(".number-guest-box").removeClass("active");
      $("#" + index + "-guest").addClass("active");
      this.numberOfGuestsSelected = index;
      localStorage.removeItem(localStorageName("NUMBER_OF_GUESTS"));
      localStorage.setItem(localStorageName("NUMBER_OF_GUESTS"), index);
    },
    gotoCategory() {
      this.$router.push(this.prefixUrl + "/category");
    },
    getNumberOfGuest() {
      this.isLoadDone = false;
      this.showLoader();
      let dataForm = {
        branch_code: this.$store.state.branchCode,
        table_code: this.tableCode,
      };

      OrderService.getNumberOfGuests(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.numberOfGuestsSelected = response.data.data;
            this.isLoadDone = true;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.setTimeOutNotification();
          this.hideLoader();
        }
      );
    },
    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },
    showLoader() {
      this.loaderActive = true;
    },
    hideLoader() {
      this.loaderActive = false;
    },
  },
};
</script>

<style scoped>
.l-header {
  width: 100%;
  background: var(--color);
  padding: 15px;
}
.l-form .title > div:first-child {
  font-size: 18px;
  font-weight: bold;
  font-style: italic;
}

.l-form .title > div:last-child {
  font-size: 12px;
  font-style: italic;
  margin-top: 5px;
}

.get-otp {
  color: #ffa502;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(0, -50%);
  cursor: pointer;
  font-size: 15px;
}

.btn-submit {
  width: 100%;
  padding: 10px;
  border-radius: 0;
  background-color: var(--color);
  color: #fff !important;
}
.number-guest-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.number-guest-list > .number-guest-box {
  text-align: center;
  border: 1px solid var(--color);
  color: var(--color);
  flex: 1 0 15%;
  max-width: 18% !important;
  padding: 10px;
  font-size: 18px;
  font-weight: bold;
  margin: 10px calc(10% / 5);
}

.number-guest-list > .number-guest-box.active {
  background: var(--color);
  color: #fff !important;
}
</style>
